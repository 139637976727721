import  React from 'react';
import { Link } from 'gatsby';
import {Link as GoLink} from 'react-scroll/modules';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';
import Image from '../components/Image';

const CompanyPage = () => (
      <Layout page="company">
        <Seo title="COMPANY"/>
        <section className="page-head">
          <ListBrand/>
          <ListLink/>
          <h2 className="main-title"><span>COMPANY</span><br className="sp" /><span>PROFILE</span></h2>
        </section>
        <section className="page-content flex-layout">
          <div className="main-content">
            <div id="company" className="content-block">
              <h3 className="sub-title"><span>会社概要</span></h3>
              <table className="table-base">
                <tr>
                  <th>名称</th>
                  <td>株式会社新宮商行 SHINGU SHOKO,LTD.</td>
                </tr>
                <tr>
                  <th>創業</th>
                  <td>1906年（明治39年）</td>
                </tr>
                <tr>
                  <th>設立</th>
                  <td>1919年（大正8年）</td>
                </tr>
                <tr>
                  <th>資本金</th>
                  <td>2億5,000万円</td>
                </tr>
                <tr>
                  <th>代表者</th>
                  <td>坂口栄治郎</td>
                </tr>
                <tr>
                  <th>売上高</th>
                  <td>69億円（2019年9月期）</td>
                </tr>
                <tr>
                  <th>従業員数</th>
                  <td>177名</td>
                </tr>
                <tr>
                  <th>営業内容</th>
                  <td>
                    <div className="mb-4">
                      <span className="c-yellow">■木材部</span><br />
                      木材製品の輸入および製造販売、<br />
                      木質住宅部材ならびに防腐加工材の製造販売
                    </div>
                    <div className="mb-4">
                      <span className="c-yellow">■機械部</span>
                      <ul>
                        <li className="listmark">・チェンソー・刈払機・ヘイカッター等の農林業機械</li>
                        <li className="listmark">・芝刈機・スイーパー・ヘッジトリマー・薪割機等の園芸機械</li>
                        <li className="listmark">・エアーソー等の産業機械</li>
                        <li className="listmark">・ハーベスター・グラップル等の高性能林業機械</li>
                        <li className="listmark">・薪ストーブ・煙突・ストーブアクセサリ等の</li>
                      </ul>
                    </div>
                    <span className="c-yellow">■山林部</span><br />
                    社有林の経営および管理
                  </td>
                </tr>
              </table>
            </div>
            <div id="access" className="content-block">
              <h3 className="sub-title">ACCESS<span>機械部所在地</span></h3>
              <div className="md:flex justify-between">
                <div className="md:w-5/12">
                  <Image className="full-image" filename="company/access-image.jpg" />
                </div>
                <div className="md:w-6/12">
                  <Image filename="company/name-logo.png" />
                  <p className="mt-6">〒270-2231 千葉県松戸市稔台6丁目7番5号<br />
                    TEL : 047-361-4701(代)<br />
                    FAX : 047-362-0157(代)</p>
                  <a href="https://www.shingu-shoko.co.jp/" className="text-link max en white py-0 mt-8" target="_blank" rel="noreferrer">
                    公式サイトはこちらから
                  </a>
                </div>
              </div>
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3236.6728129292846!2d139.9207836152615!3d35.783412680170684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601884624c04bd47%3A0x461e7dcbeff3b61!2z44CSMjcwLTIyMzEg5Y2D6JGJ55yM5p2-5oi45biC56iU5Y-w77yW5LiB55uu77yX4oiS77yV!5e0!3m2!1sja!2sjp!4v1621316158629!5m2!1sja!2sjp"
                  width="100%" height="300" allowFullScreen="" className="mt-8"
                  loading="lazy" title="所在地" />
            </div>
          </div>
          <div className="side-list">
        <ul className="sticky">
              <li>
                <GoLink to="company" spy smooth duration={800}>
                  会社概要
                </GoLink>
              </li>
              <li>
                <GoLink to="access" spy smooth duration={800}>
                  本部所在地
                </GoLink>
              </li>
              <li>
                <a href="https://www.shingu-shoko.co.jp/" className="c-yellow" target="_blank" rel="noreferrer">
                  株式会社新宮商行 公式サイト
                </a>
              </li>
            </ul>
          </div>
        </section>
        <section className="bread">
          <ul>
            <li>
              <Link to="/">
                <Image filename="ico/home.png" />
              </Link>
            </li>
            <li>会社概要</li>
          </ul>
        </section>
      </Layout>
  );

export default CompanyPage;
